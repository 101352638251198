import React from 'react'
import styled from 'styled-components'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { useTheme } from '@material-ui/core/styles'

const Container = styled.div`
  height: ${({ height }) => (height ? height : 100)}vh;
`
// const BackgroundContainer = styled.div`
// 	position: relative;
// 	overflow: hidden;
// 	height: 100%;

// 	::before {
// 		content: "";
// 		position: absolute;
// 		width: 100%;
// 		height: 100%;
//     background-image: url("${({ background }) => background}");
//     background-repeat:no-repeat;
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
// 		background-size: cover;
// 		background-position: center;
// 		background-attachment: fixed;
//     filter: grayscale(${({ grayscale }) => grayscale || 0}%)
//             brightness(${({ brightness }) => brightness || 1})
//             blur(${({ blur }) => blur || 0}px);
//   }

// 	@media (max-width: 800px) {
// 		::before {
//       background-attachment:scroll
// 		}
// 	}
// `

const StyledBackgroundImage = styled(BackgroundImage)`
  ${'' /* https://github.com/gatsbyjs/gatsby/issues/10988#issuecomment-499260410 */}
  body.hero & {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    filter: grayscale(${({ grayscale }) => grayscale || 0}%)
      brightness(${({ brightness }) => brightness || 1})
      blur(${({ blur }) => blur || 0}px);
  }
  &::before,
  &::after {
    filter: grayscale(${({ grayscale }) => grayscale || 0}%)
      brightness(${({ brightness }) => brightness || 1})
      blur(${({ blur }) => blur || 0}px);
  }

  @media (max-width: 800px) {
    ::before {
      background-attachment: scroll;
    }
  }
`

const TitleContainer = styled.div`
  padding-top: 16px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const IntroContainer = styled.div`
  position: absolute;
  margin: 0;
  right: 20px;
  bottom: 20px;
  max-width: 45%;

  @media (max-width: 500px) {
    bottom: 15vh;
    ::before {
      max-width: 100%;
    }
  }
`

const Intro = styled.p`
  background-color: ${({ theme }) => theme.palette.background.default};
  font-size: 0.75em;
  line-height: 0.75;
  display: inline;
  padding: 0.4em 0px;
  box-shadow: 5px 0 0 ${({ theme }) => theme.palette.background.default},
    -5px 0 0 ${({ theme }) => theme.palette.background.default};
`

export default function Landing(props) {
  const theme = useTheme()

  const image = getImage(props.backgroundImage)
  const bgImage = convertToBgImage(image)
  console.log('Background image data:', bgImage)
  console.log('Landing props', props)

  // return (
  //   <Container theme={theme} height={props.height}>
  //     <BackgroundContainer
  //       background={props.background}
  //       brightness={props.brightness}
  //       grayscale={props.grayscale}
  //       blur={props.blur}
  //     >
  //       <TitleContainer>{props.title}</TitleContainer>
  //       {props.children}
  //       <IntroContainer>
  //         <Intro theme={theme}>{props.intro}</Intro>
  //       </IntroContainer>
  //     </BackgroundContainer>
  //   </Container>
  // )

  return (
    <StyledBackgroundImage
      {...bgImage}
      preserveStackingContext
      keepStatic
      brightness={props.brightness}
      grayscale={props.grayscale}
      blur={props.blur}
      className="hero"
    >
      <Container theme={theme} height={props.height}>
        <TitleContainer>{props.title}</TitleContainer>
        {props.children}
        <IntroContainer>
          <Intro theme={theme}>{props.intro}</Intro>
        </IntroContainer>
      </Container>
    </StyledBackgroundImage>
  )
}
